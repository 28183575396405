exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-gigs-js": () => import("./../../../src/pages/gigs.js" /* webpackChunkName: "component---src-pages-gigs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-templates-project-js-content-file-path-src-projects-fender-butterscotch-telecaster-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/opt/build/repo/src/projects/fender-butterscotch-telecaster.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-src-projects-fender-butterscotch-telecaster-mdx" */),
  "component---src-templates-project-js-content-file-path-src-projects-fundamentals-of-audio-technology-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/opt/build/repo/src/projects/fundamentals-of-audio-technology.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-src-projects-fundamentals-of-audio-technology-mdx" */),
  "component---src-templates-project-js-content-file-path-src-projects-practice-vs-rehearsal-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/opt/build/repo/src/projects/practice-vs-rehearsal.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-src-projects-practice-vs-rehearsal-mdx" */)
}

