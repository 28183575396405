import styled from 'styled-components';

export const Button = styled.button`
	font-family: var(--headingFont);
	font-size: 2rem;
	position: fixed;
	top: var(--headerHeight);
	right: unset;
	bottom: unset;
	left: var(--gap);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	z-index: 1;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		right: calc(-1 * var(--gap));
		bottom: 0;
		left: calc(-1 * var(--gap));
	}

	&.variant {
		background: none;
		border: none;
	}
`;
