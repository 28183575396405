import styled from 'styled-components';

const Tile = styled.div`
	padding: var(--gap);
	background: var(--cardBg);
	box-shadow: var(--level-2);
	border-radius: var(--borderRadius);
	color: var(--color);
	height: 100%;

	.gatsby-image-wrapper {
		height: 100%;
	}
`;

export default Tile;
