import { isValidDate } from './isValidDate';

/**
 * Helper to check if a date is in the future
 * @param { Date } date
 * @returns { Boolean }
 */
export function isFutureDate(date, delay = 0) {
	const newDate = new Date(date);
	const today = new Date();
	const currentTime = today.setHours(today.getHours() - delay); // delay time by number of hours

	if (newDate && isValidDate(newDate)) {
		return newDate > currentTime;
	}
}
