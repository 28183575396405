import styled from 'styled-components';
import { media } from '../../utils';

export const Wrapper = styled.div`
	max-width: 65rem;
	margin: auto;

	h1 {
		text-align: center;
	}
`;

export const List = styled.ul`
	list-style: none;
	padding: 0;
	text-align: center;
	display: grid;
	gap: var(--gap);
	grid-auto-rows: 1fr;

	${media.small`	
		grid-template-columns: repeat(2, 1fr);
	`}

	${media.medium`	
		grid-template-columns: repeat(3, 1fr);
	`}

	${media.large`	
		grid-template-columns: repeat(4, 1fr);
		
	`}
`;

export const Heading = styled.h1`
	text-align: center;
`;

export const Subheading = styled.h2`
	text-align: center;
	margin: 20px auto 0px;
	font-size: larger;
`;

export const ImageWrapper = styled.div`
	border-radius: var(--borderRadius);
	overflow: hidden;
	height: 60%;
`;

export const Time = styled.time`
	color: var(--gray);
`;
