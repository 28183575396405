import React from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import { Item, List, Overlay, Tag } from './Nav.styled';
import { Icon } from '../Icon';

const links = [
	{
		title: 'Gigs',
		route: '/gigs',
	},
	{
		title: 'Projects',
		route: '/projects',
	},
	{
		title: 'About',
		route: '/about',
	},
];

export default function Nav({ isNavOpen, onClose }) {
	const { pathname } = useLocation();
	return (
		<Tag $isNavOpen={isNavOpen} aria-expanded={isNavOpen}>
			{isNavOpen && <Overlay />}
			{links && (
				<List onClick={onClose}>
					{links.map((link, i) => (
						<Item key={i}>
							<Link
								to={link.route}
								className={
									pathname.includes(link.route)
										? 'active'
										: undefined
								}
							>
								<span>{link.title}</span>
							</Link>
						</Item>
					))}
					<Item>
						<Link to="/" aria-label="Home link" title="Home link">
							<Icon type="home" size="1.2rem" />
						</Link>
					</Item>
				</List>
			)}
		</Tag>
	);
}
