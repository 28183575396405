import React from 'react';
import { navigate } from 'gatsby';
import { Button } from './Back.styled';

// https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#navigate-to-the-previous-page

export default function Back({ title }) {
	return (
		<Button
			aria-label={title}
			title={title}
			className="button variant"
			onClick={() => {
				// TODO: ...
				// https://www.gatsbyjs.com/docs/how-to/local-development/environment-variables/
				// https://stackoverflow.com/questions/4362761/how-can-i-determine-if-the-document-referrer-is-from-my-own-site
				// https://stackoverflow.com/questions/61936140/detect-previous-path-in-react-router-if-i-came-from-an-outside-application
				navigate(-1);
			}}
		>
			&larr;
		</Button>
	);
}
