import styled from 'styled-components';
import { media } from '../../utils';

export const Wrapper = styled.div`
	position: relative;
	max-height: var(--bannerHeight);
	display: grid;
	background: rgb(var(--bg) / 70%);
	overflow: hidden;

	.gatsby-image-wrapper {
		position: unset;
		overflow: hidden;
		grid-row: 1;
		grid-column: 1;
		max-height: var(--bannerHeight);

		img {
			object-position: center;
		}
	}

	.banner_content {
		color: var(--white);
		grid-row: 1;
		grid-column: 1;
		margin: 1rem;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-start;
		position: relative;

		${media.medium`
			margin: 2rem;
		`}
	}

	.title {
		margin: 0;

		${media.small`
			margin: var(--gap) auto;
		`}
	}

	.tagline {
		font-size: 1.2rem;
		font-style: italic;
		text-align: center;
		margin: 0;
		max-width: 600px;
	}

	.shades {
		display: contents;
		position: relative;
		grid-row: 1;
		grid-column: 1;
	}

	.shades > div {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		${media.small`
			
		`}
	}

	.shades > div:nth-child(1) {
		background-image: linear-gradient(
			rgba(0, 0, 0, 0.24),
			rgba(0, 0, 0, 0.24) 2.45%,
			rgba(0, 0, 0, 0.23) 4.91%,
			rgba(0, 0, 0, 0.22) 7.36%,
			rgba(0, 0, 0, 0.204) 9.81%,
			rgba(0, 0, 0, 0.184) 12.27%,
			rgba(0, 0, 0, 0.16) 14.72%,
			rgba(0, 0, 0, 0.133) 17.18%,
			rgba(0, 0, 0, 0.106) 19.63%,
			rgba(0, 0, 0, 0.08) 22.08%,
			rgba(0, 0, 0, 0.055) 24.54%,
			rgba(0, 0, 0, 0.035) 26.99%,
			rgba(0, 0, 0, 0.02) 29.44%,
			rgba(0, 0, 0, 0.008) 31.9%,
			rgba(0, 0, 0, 0.004) 34.35%,
			rgba(0, 0, 0, 0) 36.81%
		);
	}

	.shades > div:nth-child(2) {
		background-image: linear-gradient(
			1.5708rad,
			rgba(0, 0, 0, 0.24) 24.53%,
			rgba(0, 0, 0, 0.24) 26.03%,
			rgba(0, 0, 0, 0.23) 27.53%,
			rgba(0, 0, 0, 0.22) 29.03%,
			rgba(0, 0, 0, 0.204) 30.54%,
			rgba(0, 0, 0, 0.184) 32.04%,
			rgba(0, 0, 0, 0.16) 33.54%,
			rgba(0, 0, 0, 0.133) 35.04%,
			rgba(0, 0, 0, 0.106) 36.54%,
			rgba(0, 0, 0, 0.08) 38.05%,
			rgba(0, 0, 0, 0.055) 39.55%,
			rgba(0, 0, 0, 0.035) 41.05%,
			rgba(0, 0, 0, 0.02) 42.55%,
			rgba(0, 0, 0, 0.008) 44.05%,
			rgba(0, 0, 0, 0.004) 45.56%,
			rgba(0, 0, 0, 0) 47.06%
		);
	}

	.shades > div:nth-child(3) {
		background-image: linear-gradient(
			0.675rad,
			rgba(0, 0, 0, 0.4),
			rgba(0, 0, 0, 0.396) 3.33%,
			rgba(0, 0, 0, 0.384) 6.67%,
			rgba(0, 0, 0, 0.37) 10%,
			rgba(0, 0, 0, 0.34) 13.33%,
			rgba(0, 0, 0, 0.306) 16.67%,
			rgba(0, 0, 0, 0.267) 20%,
			rgba(0, 0, 0, 0.224) 23.33%,
			rgba(0, 0, 0, 0.176) 26.67%,
			rgba(0, 0, 0, 0.133) 30%,
			rgba(0, 0, 0, 0.094) 33.33%,
			rgba(0, 0, 0, 0.06) 36.67%,
			rgba(0, 0, 0, 0.03) 40%,
			rgba(0, 0, 0, 0.016) 43.33%,
			rgba(0, 0, 0, 0.004) 46.67%,
			rgba(0, 0, 0, 0) 50%
		);
	}
	.shades > div:nth-child(4) {
		background-image: linear-gradient(
			0.277093rad,
			rgba(0, 0, 0, 0.6) 8.18%,
			rgba(0, 0, 0, 0.596) 11.46%,
			rgba(0, 0, 0, 0.58) 14.75%,
			rgba(0, 0, 0, 0.553) 18.03%,
			rgba(0, 0, 0, 0.51) 21.32%,
			rgba(0, 0, 0, 0.463) 24.61%,
			rgba(0, 0, 0, 0.4) 27.89%,
			rgba(0, 0, 0, 0.333) 31.18%,
			rgba(0, 0, 0, 0.267) 34.46%,
			rgba(0, 0, 0, 0.2) 37.75%,
			rgba(0, 0, 0, 0.137) 41.03%,
			rgba(0, 0, 0, 0.09) 44.32%,
			rgba(0, 0, 0, 0.05) 47.61%,
			rgba(0, 0, 0, 0.02) 50.89%,
			rgba(0, 0, 0, 0.004) 54.18%,
			rgba(0, 0, 0, 0) 57.46%
		);
	}

	.shades > div:nth-child(5) {
		background-image: linear-gradient(
			3.8615rad,
			rgba(0, 0, 0, 0.4),
			rgba(0, 0, 0, 0.3) 13.33%,
			rgba(0, 0, 0, 0.2) 20%,
			rgba(0, 0, 0, 0.15) 26.67%,
			rgba(0, 0, 0, 0.05) 33.33%,
			rgba(0, 0, 0, 0) 40%
		);
	}

	.shades > div:nth-child(6) {
		background-image: linear-gradient(
			rgba(0, 0, 0, 0) 49.02%,
			rgba(0, 0, 0, 0.008) 52.42%,
			rgba(0, 0, 0, 0.035) 55.82%,
			rgba(0, 0, 0, 0.082) 59.22%,
			rgba(0, 0, 0, 0.15) 62.62%,
			rgba(0, 0, 0, 0.23) 66.02%,
			rgba(0, 0, 0, 0.333) 69.41%,
			rgba(0, 0, 0, 0.443) 72.81%,
			rgba(0, 0, 0, 0.557) 76.21%,
			rgba(0, 0, 0, 0.667) 79.61%,
			rgba(0, 0, 0, 0.77) 83.01%,
			rgba(0, 0, 0, 0.85) 86.41%,
			rgba(0, 0, 0, 0.918) 89.8%,
			rgba(0, 0, 0, 0.965) 93.2%,
			rgba(0, 0, 0, 0.992) 96.6%,
			rgb(0, 0, 0) 100%
		);
	}
`;
