import React from 'react';

export default function Quote({ text, cite }) {
	return (
		<blockquote>
			{text && <p>{text}</p>}
			{cite && <cite>&mdash; {cite}</cite>}
		</blockquote>
	);
}
